/* src/components/core/Footer.css */

/* Footer Styling */
.footer {
  background-color: #0c2032; /* New background color */
  color: white;
  text-align: center;
  padding: 1rem 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for elevation */
}

.footer a {
  color: #f4b400; /* Link color in footer */
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.footer a:hover {
  text-decoration: underline;
  color: #ffd23f; /* Highlighted link color */
}

.footer p {
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
  color: #e0e0e0;
  font-family: 'Arial', sans-serif;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}


/* Responsive Footer Padding */
@media (max-width: 768px) {
  .footer {
    padding: 0.75rem 0;
  }
}
