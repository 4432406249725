.get-started-section {
    padding: 4rem 1rem;
    background-color: #f4f4f4;
    text-align: center;
}

.get-started-section .container {
    max-width: 1200px;
    margin: 0 auto;
}

.get-started-section h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #333;
}

.steps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
}

.step-card {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
}

.step-number {
    width: 40px;
    height: 40px;
    background-color: #f4b400;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    font-weight: bold;
}

.step-card h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.step-card p {
    color: #666;
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.step-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #f4b400;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.step-button:hover {
    background-color: #d69e00;
}

.disclaimer {
    color: #666;
    font-size: 0.9rem;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .get-started-section {
        padding: 2rem 1rem;
    }

    .get-started-section h1 {
        font-size: 2rem;
    }

    .steps-grid {
        grid-template-columns: 1fr;
    }
} 