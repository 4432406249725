/* src/components/sections/FeatureCard.css */
.feature-card {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .feature-card h3 {
    font-size: 1.5rem;
    color: #2b2d42;
    margin-bottom: 1rem;
  }
  
  .feature-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  