/* src/components/core/Navbar.css */

/* Navbar Styling */
.navbar {
  position: sticky; /* Sticks the navbar to the top on scroll */
  top: 0;
  z-index: 1000; /* Keeps it above other content */
  background-color: #0c2032; /* Background color */
  color: white;
  padding: 0.5rem; /* Adjusted padding for cleaner look */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Logo Section */
.logo-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-logo {
  height: 40px; /* Logo height */
}

.navbar-name {
  height: 24px; /* Name image height */
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  gap: 4px;
  border: none;
  background: none;
  cursor: pointer;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background: white;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #f4b400; /* Highlight color for links */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-links {
    display: none;
  }

  .nav-links.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%; /* Align under navbar */
    right: 0;
    background: #0c2032;
    padding: 1rem;
    width: 200px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
