.about-page {
  font-family: 'Arial', sans-serif;
}

/* Hero Section */
.about-hero {
  position: relative;
  height: 58vh; /* Adjusted height for a balanced view */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.about-hero-background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Enables parallax scrolling */
  position: absolute;
  top: -30px;
  left: 0;
  z-index: -1;
}

.about-hero .container {
  position: relative;
  z-index: 2;
  max-width: 800px;
}

.about-hero h1 {
  font-size: 3.5rem;
  margin: 0 0 10px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

.about-hero .hero-subheadline {
  font-size: 1.6rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

/* General Section Styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.about-what,
.about-why,
.about-cta {
  margin: 50px 0;
}

.about-what h2,
.about-why h2,
.about-cta h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-what p,
.about-why ul {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
}

/* Why Choose Us Section */
.about-why ul {
  list-style: none;
  padding: 0;
}

.about-why li {
  margin-bottom: 15px;
  text-align: left;
}

/* Call-to-Action Section */
.about-cta {
  background: #f4f4f4;
  text-align: center;
  padding: 30px 20px;
}

.about-cta .cta-buttons {
  margin-top: 20px;
}

.about-cta .cta-button {
  display: inline-block;
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.about-cta .cta-button:hover {
  background: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .about-hero {
    height: 60vh; /* Further reduced for smaller screens */
  }

  .about-hero-background {
    background-attachment: scroll; /* Disable parallax on smaller devices */
  }

  .about-hero h1 {
    font-size: 2.5rem;
  }

  .about-hero .hero-subheadline {
    font-size: 1.2rem;
  }

  .container {
    padding: 1.5rem;
  }
}
