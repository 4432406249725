/* src/components/sections/HeroSection.css */

/* Hero Section Styling */
.hero-section {
  height: 500px; /* Increased height for a larger viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-size: cover; /* Ensures the image fills the section */
  background-position: top center; /* Adjusts the focal point of the image */
  background-repeat: no-repeat;
  background-attachment: fixed; /* Enables parallax scrolling */
  position: relative; /* For layering effects */
}

/* Add a dark overlay to improve text visibility */
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  z-index: 1;
}

/* Hero Content Styling */
.hero-content {
  max-width: 800px;
  padding: 1rem;
  position: relative; /* Ensures it sits above the overlay */
  z-index: 2;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8); /* Text shadow for visibility */
}

.hero-content h1 {
  font-size: 3rem; /* Large headline text */
  margin: 0;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.5rem; /* Subheadline text */
  margin-top: 0.5rem;
  line-height: 1.6;
}

/* Button Styling */
.hero-buttons {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem; /* Space between buttons */
  justify-content: center;
}

.hero-buttons a {
  text-decoration: none;
  color: white;
  background-color: #f4b400; /* Button background */
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-buttons a:hover {
  background-color: #c49200; /* Darker hover color */
  transform: scale(1.05); /* Slightly enlarge on hover */
}
