.models-page-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

/* Header Section */
.models-header {
  margin-bottom: 2rem;
}

.title-section {
  margin-bottom: 2rem;
}

.title-section h1 {
  font-size: 2.5rem;
  color: #0c2032;
  margin-bottom: 0.5rem;
}

.title-section p {
  color: #4a5568;
  font-size: 1.1rem;
}

/* Controls Section */
.controls-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.search-box {
  flex: 1;
  min-width: 250px;
}

.search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.view-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.sort-select {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  min-width: 200px;
}

.view-toggles {
  display: flex;
  gap: 0.5rem;
}

.view-toggle {
  padding: 0.75rem 1.5rem;
  border: 1px solid #ddd;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.view-toggle.active {
  background: #f4b400;
  color: white;
  border-color: #f4b400;
}

/* Models Grid */
.models-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.models-grid {
  display: grid;
  gap: 1rem;
}

.models-grid.grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.models-grid.list {
  grid-template-columns: 1fr;
}

/* Model Cards */
.model-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  min-height: 160px;
}

.model-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.model-card.selected {
  border-color: #f4b400;
  box-shadow: 0 0 0 2px #f4b400;
}

.model-card-header {
  margin-bottom: 1rem;
}

.model-card-header h3 {
  color: #0c2032;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.model-card-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: auto;
}

.stat {
  text-align: center;
}

.stat label {
  display: block;
  color: #718096;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.stat span {
  color: #0c2032;
  font-weight: 600;
  font-size: 1.1rem;
}

/* Model Details Panel */
.model-details-panel {
  background: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 2rem;
  position: sticky;
  top: 2rem;
}

.model-details h2 {
  color: #0c2032;
  margin: 0 0 1.5rem 0;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.detail-item {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.detail-item label {
  display: block;
  color: #718096;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.detail-item span {
  color: #0c2032;
  font-weight: 600;
  font-size: 1.1rem;
}

.model-visualization {
  margin-top: 2rem;
  position: relative;
  cursor: pointer;
}

.model-visualization img {
  width: 100%;
  border-radius: 8px;
  transition: opacity 0.3s ease;
}

.model-visualization:hover img {
  opacity: 0.95;
}

.expand-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 4px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  color: #0c2032;
}

.model-visualization:hover .expand-button {
  opacity: 1;
}

.expand-button:hover {
  background: white;
}

/* Visualization Modal */
.visualization-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  position: relative;
  max-width: 95vw;
  max-height: 95vh;
  background: white;
  border-radius: 12px;
  padding: 2rem;
  animation: zoomIn 0.3s ease;
}

.modal-content h2 {
  color: #0c2032;
  margin: 0 0 1.5rem 0;
  padding-right: 2rem;
}

.modal-content img {
  max-width: 100%;
  max-height: calc(95vh - 6rem);
  object-fit: contain;
  border-radius: 8px;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  line-height: 1;
  color: #0c2032;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.close-modal:hover {
  color: #f4b400;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .models-content {
    grid-template-columns: 1fr;
  }

  .model-details-panel {
    position: static;
  }
}

@media (max-width: 768px) {
  .models-page-content {
    padding: 1rem;
  }

  .controls-section {
    flex-direction: column;
  }

  .view-controls {
    width: 100%;
    flex-direction: column;
  }

  .sort-select {
    width: 100%;
  }

  .view-toggles {
    width: 100%;
  }

  .view-toggle {
    flex: 1;
  }

  .details-grid {
    grid-template-columns: 1fr;
  }
}
