/* src/components/sections/FeatureSection.css */
.feature-section {
    padding: 4rem 1rem;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .feature-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  